import { Container, Grid } from '@mui/material'
import { RegisterForm } from '../components/RegisterForm/RegisterForm'

function RegisterContainer() {
  return (
    <Grid container item xs={12} md={10} xl={8} style={{ zIndex: 5 }}>
      <Container>
        <RegisterForm />
      </Container>
    </Grid>
  )
}

export default RegisterContainer
