class ChatBotAPI {
    openChatBot() {
        let chatBot: HTMLElement | null = document.querySelector('#chat_bot_logo');
    
        if (chatBot) {
            let activeChat = document.querySelector('#active_chat');
            if (!activeChat) {
                chatBot.click();
            }
        }
    }
    
    closeChatBot() {
        let chatBot: HTMLElement | null = document.querySelector('#chat_bot_logo');
    
        if (chatBot) {
            let activeChat = document.querySelector('#active_chat');
            if (activeChat) {
                chatBot.click();
            }
        }
    }
    
    hideChatBot() {
        this.closeChatBot();
    
        let chatBot: HTMLElement | null = document.querySelector('.chat-bot-container');
    
        if (chatBot) {
            chatBot.style.display = "none";
        }
    }
    
    unhideChatBot() {
        let chatBot: HTMLElement | null = document.querySelector('.chat-bot-container');
    
        if (chatBot) {
            chatBot.style.display = "flex";
        }
    }
    
    
    selectQuestion(questionId: number) {
        setTimeout(() => {
            let questionButton: HTMLElement | null = document.querySelector(`#active_chat button#\\3${questionId}:not([disabled])`)
            questionButton?.click();
        }, 500);
    }
}

export default new ChatBotAPI();