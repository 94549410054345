import { StyledEngineProvider, adaptV4Theme, createTheme } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { StylesProvider } from '@mui/styles'

import { Theme } from '@mui/material/styles'
import ReactDOM from 'react-dom'
import App from './App'
import './assets/fonts/Arimo-Bold.ttf'
import './assets/fonts/Arimo-Regular.ttf'
import './assets/fonts/NarkisBlock-Condensed_MFW_medium.ttf'
import './index.css'
import * as serviceWorker from './serviceWorker'

declare module '@mui/styles' {
  interface DefaultTheme extends Theme {}
}

// jss rtl workaround
const styleNode = document.createComment('jss-insertion-point')
document.head.insertBefore(styleNode, document.head.firstChild)

// Creating app theme
const theme = createTheme(
  adaptV4Theme({
    direction: 'rtl',
    typography: {
      body1: {
        lineHeight: 1.3,
      },
      fontFamily: ['NarkisBlock', 'Helvetica', 'sans-serif'].join(','),
      fontSize: 18,
    },
    palette: {
      primary: {
        main: '#fde85e',
      },
      text: {
        primary: '#333',
      },
    },
  })
)

// Render the root component
ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <StylesProvider>
        <App />
      </StylesProvider>
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
