import { Visibility, VisibilityOff } from '@mui/icons-material'
import Send from '@mui/icons-material/Send'
import { CircularProgress, Container, Grid, IconButton, InputAdornment, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTimer } from '../../../hooks/timerHook'
import MeApiService from '../../../services/rest/MeApiService'
import UsersApiService from '../../../services/rest/UsersApiService'
import CensorPhone from '../../CensorPhone/CensorPhone'
import ChatBotAPI from '../../ChatBot/ChatBotAPI'
import { ClkInput } from '../../ClkInput/ClkInput'
import { ERRORS } from '../../Common/Errors/ErrorMessages'
import { MessageAlert } from '../../Common/MessageAlert'
import IFormProps from '../IForm'
import RegisteredUserSection from './RegisteredUserSection'
import './ValidateOtp.css'

function CodeForm(props: IFormProps) {
  const waitingTime = 15
  // State & props
  const { onResolve = (arg) => {}, payload } = props
  const [error, setError] = useState<any>({ msg: '', severity: 'error' })
  const [isLoading, setIsLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [codeInput, setCodeInput] = useState('')
  const [isRegistered, setIsRegistered] = useState(false)
  const [isUserLocked, setIsUserLocked] = useState(false)
  const [isUserInitiallyLocked, setIsUserInitiallyLocked] = useState(false)
  const [userPrincipalName, setUserPrincipalName] = useState('')
  const [showUserUnlockedMessage, setShowUserUnlockedMessage] = useState(false)
  const [showResetPasswordSuccessfulMessage, setShowResetPasswordSuccessfulMessage] = useState(false)
  const [isLoadingResetPassword, setIsLoadingResetPassword] = useState(false)
  const [isLoadingUnlockUser, setIsLoadingUnlockUser] = useState(false)
  const [isResetedSuccessfully, setIsResetedSuccessfully] = useState(false)

  const openChatBotQuestion = () => {
    ChatBotAPI.openChatBot()
    ChatBotAPI.selectQuestion(2)
  }

  const [time, setTime, timerOn, setTimerOn] = useTimer(waitingTime, openChatBotQuestion)

  // Methods

  const isValidInput = (input: string) => {
    const isValidInput = input.length === 8

    return isValidInput
  }

  const isCodeMatch = async () => {
    const { id } = payload

    setError({ msg: '', severity: 'error' })
    setIsLoading(true)

    try {
      const { isValid, isRegistered, upn, isUserLocked } = await UsersApiService.validateCode(id, codeInput)

      setIsLoading(false)

      if (!isValid) {
        // first check if the entered code was valid
        setError({
          msg: ERRORS.invalidCode,
          severity: 'error',
        })
      } else if (isRegistered) {
        // next check if the user is already registered
        setIsRegistered(true)
        setUserPrincipalName(upn)
        props.onCompleteAllSteps &&
          props.onCompleteAllSteps({
            isRegistered,
            userPrincipalName: upn,
            showUserUnlockedMessage,
            isLoadingUnlockUser,
            isUserInitiallyLocked: isUserLocked,
            isUserLocked: isUserLocked,
          })
        if (isUserLocked) {
          // if the user is registered but locked
          setIsUserLocked(true)
          setIsUserInitiallyLocked(true) // this will tell us if the user was locked so we can show him a success message when he unlocks his user successfully
        }
      } else {
        onResolve({ ...payload, code: codeInput })
      }
    } catch (err) {
      setIsLoading(false)

      setError({
        msg: ERRORS.general,
        severity: 'error',
      })
    }
  }

  // Handlers
  const onChange = (value: any) => {
    const inputVal = value.target.value

    // stop the timer to prevent the chat bot to pop up in case the user starts to enter the validation code
    if (timerOn) {
      setTimerOn(false)
    }

    setCodeInput(inputVal)
  }

  const handleClickShowPassword = (event) => {
    setShowPassword(!showPassword)
  }

  const submit = async () => {
    if (isLoading) return

    if (isValidInput(codeInput)) {
      setIsLoading(true)
      setTimerOn(false)
      await isCodeMatch()
    } else {
      setError({
        msg: ERRORS.invalidCode,
        severity: 'error',
      })
    }
  }
  const handleSubmit = async (event) => {
    event.preventDefault()

    await submit()
  }

  const renderCodeInput = () => {
    return (
      <React.Fragment>
        <Typography className="bold">נשלח אלייך קוד לטלפון:</Typography>
        <CensorPhone phone={payload.mobilePhone} stringToReplace="X" />

        <Grid container direction="column" justifyContent="center" alignItems="center" style={{ margin: '10px 0px' }}>
          <Typography>נא להזין את הקוד שנשלח:​</Typography>
          <Grid item md={6}>
            <form noValidate onSubmit={handleSubmit}>
              <ClkInput
                type={showPassword ? 'text' : 'password'}
                onChange={onChange}
                value={codeInput}
                className="clk-input input-password"
                endAdornment={
                  <InputAdornment position="end" onClick={handleSubmit}>
                    {isLoading ? (
                      <CircularProgress
                        color="primary"
                        size={23}
                        thickness={7}
                        style={{ marginLeft: '10px', cursor: 'default' }}
                      />
                    ) : (
                      <IconButton size="small">
                        <Send className="login-send-icon" />
                      </IconButton>
                    )}
                  </InputAdornment>
                }
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton
                      style={{ paddingRight: 0 }}
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                      size="large"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                placeholder={'הכנס קוד'}
                autoFocus={false}
                fullWidth
              />
            </form>
          </Grid>
          {timerOn && (
            <Grid container item xs={12} justifyContent="center">
              <Typography style={{ display: 'flex' }}>ההודעה תתקבל במכשירך ב-{waitingTime} השניות הקרובות..</Typography>
            </Grid>
          )}
        </Grid>
      </React.Fragment>
    )
  }

  const handleUserUnlock = async () => {
    try {
      setError({ msg: '', severity: 'error' })
      setIsLoadingUnlockUser(true)
      setShowUserUnlockedMessage(false)

      await MeApiService.unlockUser()
      setShowUserUnlockedMessage(true)
      setIsUserLocked(false)
    } catch (err) {
      setError({
        msg: ERRORS.general,
        severity: 'error',
      })
    } finally {
      setIsLoadingUnlockUser(false)
    }
  }

  const handleUserResetPassword = async () => {
    try {
      setError({ msg: '', severity: 'error' })
      setIsLoadingResetPassword(true)
      setShowResetPasswordSuccessfulMessage(false)

      await MeApiService.resetUserPassword()
      setShowResetPasswordSuccessfulMessage(true)
      setIsResetedSuccessfully(true)
    } catch (err) {
      setError({
        msg: ERRORS.general,
        severity: 'error',
      })
    } finally {
      setIsLoadingResetPassword(false)
    }
  }

  // Rendering
  return (
    <Container maxWidth="sm">
      {isRegistered ? (
        <MessageAlert
          alert={{
            msg: (
              <RegisteredUserSection
                userPrincipalName={userPrincipalName}
                isUserInitallyLocked={isUserInitiallyLocked}
                isUserLocked={isUserLocked}
                isLoadingUnlock={isLoadingUnlockUser}
                isLoadingResetPassword={isLoadingResetPassword}
                showUserUnlockedMessage={showUserUnlockedMessage}
                showResetPasswordSuccessfulMessage={showResetPasswordSuccessfulMessage}
                onResetPassword={handleUserResetPassword}
                onUnlockUser={handleUserUnlock}
                isResetedPassword={isResetedSuccessfully}
              />
            ),
            severity: 'info',
          }}
        />
      ) : (
        renderCodeInput()
      )}

      <MessageAlert alert={error} />
    </Container>
  )
}

export default CodeForm
