import { axiosMeInstance } from "../../clients/axiosMeClient";

class MeApiService {

	async resetUserPassword(generateCode: boolean = true) {
		const res = await axiosMeInstance.put(`/resetPassword`, {generateCode});
		return res.data;
	}

	async unlockUser() {
		const res = await axiosMeInstance.put(`/unlock`, {});
		return res.data;
	}

	async registerUser() {
		const res = await axiosMeInstance.put(`/register`);
		return res.data;
	}

	async sendFinishSMS() {
		const res = await axiosMeInstance.get(`/finish`);
		return res.data;
	}
}

export default new MeApiService();