import { ArrowForward } from '@mui/icons-material'
import { Grid, IconButton, MobileStepper, Paper, Step, StepLabel, Stepper, Tooltip } from '@mui/material'
import React, { useCallback, useMemo, useState } from 'react'
import registerSteps, { IRegisterStep } from '../../model/registration/RegisterSteps'
import { MyPaper } from '../Common/MyPaper'
import StepIcon from '../StepIcon/StepIcon'
import './RegisterForm.css'

export const RegisterForm: React.FC = () => {
  // State & props
  const steps = registerSteps
  const [currentStep, setCurrentStep] = useState(0)
  const [currentPayload, setCurrentPayload] = useState<any>({})
  const NewCurrentStep: IRegisterStep = useMemo(() => steps[currentStep], [currentStep])
  // Handlers

  //onMount

  const handleNextStep = useCallback((payload: any) => {
    setCurrentPayload(payload)
    setCurrentStep((oldVal) => (oldVal += 1))
  }, [])

  const handleReset = useCallback(() => {
    setCurrentPayload({})
    setCurrentStep(0)
  }, [])

  const handleCompleteAllSteps = useCallback((payload) => {
    setCurrentPayload(payload)
    setCurrentStep(registerSteps.length - 1)
  }, [])

  // Rendering
  return (
    <MyPaper>
      {steps[currentStep].isResettable && (
        <Tooltip title="חזרה" aria-label="חזרה" disableInteractive>
          <IconButton size="small" onClick={handleReset} className="reset-button">
            <ArrowForward />
          </IconButton>
        </Tooltip>
      )}

      <Grid container style={{ padding: '10px' }} justifyContent="center" alignItems="center" direction="column">
        {
          <NewCurrentStep.component
            onResolve={handleNextStep}
            onReset={handleReset}
            payload={currentPayload}
            onCompleteAllSteps={handleCompleteAllSteps}
          />
        }
      </Grid>

      <Grid container item justifyContent="center" alignItems="center" md={12}>
        <Grid item xs={3} sm={8} md={6}>
          {/* Mobile steps */}
          <Paper square elevation={0} sx={{ display: { sm: 'none', xs: 'initial' } }}>
            <MobileStepper
              variant="dots"
              steps={steps.length}
              style={{ background: 'white', display: 'flex', justifyContent: 'center' }}
              position="static"
              activeStep={currentStep}
              nextButton={<React.Fragment />}
              backButton={<React.Fragment />}
            />
          </Paper>
          {/* Desktop steps */}
          <Paper square elevation={0} sx={{ display: { sm: 'initial', xs: 'none' } }}>
            <Stepper style={{ padding: '5px 0px' }} alternativeLabel activeStep={currentStep}>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepLabel StepIconComponent={StepIcon} icon={step.icon}>
                    {step.title}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Paper>
        </Grid>
      </Grid>
    </MyPaper>
  )
}
