import { Paper } from '@mui/material'
import idfPng from '../../assets/images/idf.png'

export function MyPaper(props) {
  return (
    <Paper elevation={3} style={{ padding: '40px 0px', position: 'relative', borderRadius: 30 }}>
      <img src={idfPng} alt={'idf'} className="idf-icon" />
      {props.children}
    </Paper>
  )
}
