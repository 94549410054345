import { Assignment, CheckCircle, Fingerprint, PhoneAndroid } from '@mui/icons-material'
import React from 'react'
import AfterCodeReceivedForm from '../../components/Forms/AfterCodeReceivedForm/AfterCodeReceivedForm'
import IFormProps from '../../components/Forms/IForm'
import IdForm from '../../components/Forms/IdForm/IdForm'
import { SecurityAgreement } from '../../components/Forms/SecurityAgreement/SecurityAgreement'
import ValidateOtp from '../../components/Forms/ValidateOtp/ValidateOtp'

export interface IRegisterStep {
  title: string
  icon: any
  component: React.ComponentType<IFormProps>
  isResettable?: boolean
}

const registerSteps: IRegisterStep[] = [
  {
    title: 'הזדהות משתמש',
    icon: <Fingerprint />,
    component: IdForm,
    isResettable: false,
  },
  {
    title: 'אימות',
    icon: <PhoneAndroid />,
    component: ValidateOtp,
    isResettable: true,
  },
  {
    title: 'קרא וחתום ביטחון מידע',
    icon: <Assignment />,
    component: SecurityAgreement,
    isResettable: true,
  },
  {
    title: 'סיום והתחברות',
    icon: <CheckCircle />,
    component: AfterCodeReceivedForm,
  },
]

export default registerSteps
