import Send from '@mui/icons-material/Send'
import { Alert, CircularProgress, Container, Grid, IconButton, InputAdornment, Typography } from '@mui/material'
import React, { useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import config from '../../../model/configuration/Configuration'
import UsersApiService from '../../../services/rest/UsersApiService'
import { ClkInput } from '../../ClkInput/ClkInput'
import { ERRORS } from '../../Common/Errors/ErrorMessages'
import { MY_IDF } from '../../Common/General/General'
import IFormProps from '../IForm'
import './IdForm.css'

function IdForm(props: IFormProps) {
  // State & props
  const { onResolve } = props
  const [error, setError] = useState<{
    msg: JSX.Element | string
    severity: any
  }>({
    msg: '',
    severity: 'error',
  })
  const [isLoading, setIsLoading] = useState(false)
  const [idInput, setIdInput] = useState('')
  const [captchToken, setCaptchToken] = useState('')

  // Methodes
  const isFormValid = (id: string) => isValidIsraeliID(id) && isCaptchaChecked()

  const handleVerify = (token) => setCaptchToken(token)

  const isCaptchaChecked = () => captchToken !== '' && captchToken !== null

  const isValidIsraeliID = (id: string) => {
    if (id.trim().length !== 9 || +id === 0) return false

    const totalId = Array.from(id, Number).reduce((counter, digit, i) => {
      const step = digit * ((i % 2) + 1)
      const currentStep = step > 9 ? step - 9 : step
      return counter + currentStep
    })

    return totalId % 10 === 0
  }

  const isValidIdInput = (id: string) => /^\d*$/.test(id)

  const checkIsUserExist = async () => {
    setError({ msg: '', severity: 'error' })
    setIsLoading(true)

    try {
      const captchaValue = captchToken
      const data = await UsersApiService.checkUser(idInput, captchaValue)

      setIsLoading(false)
      if (data.failedCookieValidation) {
        setError({
          msg: ERRORS.general,
          severity: 'error',
        })
      } else {
        onResolve && onResolve({ id: idInput, mobilePhone: data.mobilePhone })
      }
    } catch (err) {
      setIsLoading(false)
      console.log(err)
      setError({
        msg: ERRORS.general,
        severity: 'error',
      })
    }
  }

  // Handlers
  const onChange = ({ target }: { target: HTMLInputElement | HTMLTextAreaElement }) => {
    const idFromInput = target.value

    if (isValidIdInput(idFromInput)) {
      setIdInput(idFromInput)
    }
  }

  const onClick = async (event: any) => {
    event.preventDefault()

    if (!isLoading) {
      if (!isCaptchaChecked()) {
        setError({
          msg: ERRORS.requiredCaptcha,
          severity: 'error',
        })
        return
      }
      if (!isFormValid(idInput)) {
        setError({
          msg: ERRORS.invalidId,
          severity: 'error',
        })
        return
      }

      setIsLoading(true)
      await checkIsUserExist()
    }
  }

  return (
    <>
      <Container maxWidth="sm">
        <Typography variant="h3" style={{ fontWeight: 'bold', marginBottom: 10 }}>
          ברוכים הבאים
        </Typography>

        <Typography className="bold">שירותי הדיגיטל של צה"ל עוברים להזדהות חכמה!</Typography>
        <Typography>כאן ניתן ליצור באופן עצמאי ובקלות, משתמש {MY_IDF}.</Typography>
        <Typography style={{ marginTop: 20 }}>להתחלת תהליך הרישום ולצורך אימות מול מערכת כח האדם,</Typography>
        <Typography>יש להזין מספר תעודת זהות מלא באורך 9 ספרות:</Typography>
        <Grid container direction="column" justifyContent="center" alignItems="center" style={{ margin: '10px 0px' }}>
          <Grid item md={6}>
            <form noValidate onSubmit={onClick}>
              <ClkInput
                onChange={onChange}
                value={idInput}
                type="text"
                endAdornment={
                  <InputAdornment position="end" onClick={onClick}>
                    {isLoading ? (
                      <CircularProgress
                        color="primary"
                        size={23}
                        thickness={7}
                        style={{ marginLeft: '10px', cursor: 'default' }}
                      />
                    ) : (
                      <IconButton size="small">
                        <Send className="login-send-icon" />
                      </IconButton>
                    )}
                  </InputAdornment>
                }
                placeholder={'הכנס תעודת זהות'}
                autoFocus={false}
                fullWidth
              />
            </form>
          </Grid>
          <Grid item xs={12}>
            {error && error.msg !== '' ? <Alert severity={error.severity}>{error.msg}</Alert> : <React.Fragment />}
          </Grid>
        </Grid>
      </Container>
      <ReCAPTCHA hl="iw" style={{ marginTop: 10 }} sitekey={config.captchaSiteKey} onChange={handleVerify} />
    </>
  )
}

export default IdForm
