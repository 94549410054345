import axios, { InternalAxiosRequestConfig } from 'axios'
import { getToken } from '../utilities/tokenUtils'

export const axiosMeInstance = axios.create({
  baseURL: '/api/me',
  withCredentials: true,
})

// Put the jwt token in headers of each request
axiosMeInstance.interceptors.request.use(
  (options) => {
    options = jwtOptions(options)
    return options
  },
  (err) => {
    return Promise.reject(err)
  }
)

function jwtOptions(options: InternalAxiosRequestConfig) {
  const token = getToken()

  options.headers.set('Authorization', `Bearer ${token}`)

  return options
}
