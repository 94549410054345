import { axiosValidationInstance } from '../../clients/axiosValidationClient'
import { saveToken } from '../../utilities/tokenUtils'

class UsersApiService {
  static async checkUser(idNumber: string, captchaToken: string) {
    const res = await axiosValidationInstance.post(`/`, {
      idNumber,
      'g-recaptcha-response': captchaToken,
    })
    return res.data
  }

  static async validateCode(idNumber, code) {
    const res = await axiosValidationInstance.post(`/${idNumber}/validateCode`, { code })

    // Save jwt token from the response
    const token = res.data?.token && res.data.token.split(' ')[1]
    saveToken(token)

    return res.data
  }

  static async validateOtp(idNumber, otp) {
    const res = await axiosValidationInstance.post(`/${idNumber}/validateOtp`, {
      otp,
    })
    return res.data
  }
}

export default UsersApiService
