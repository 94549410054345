import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import './App.css'
import CallbackContainer from './containers/CallbackContainer'
import RegisterContainer from './containers/RegisterContainer'

import ChatBot from './components/ChatBot/ChatBot'
import ErrorContainer from './containers/ErrorContainer'

function App() {
  return <MainApp />
}

function MainApp() {
  return (
    <div className="App">
      <div className="App-Bg"></div>
      <div className="App-content">
        <Router>
          <Routes>
            <Route path="/" element={<RegisterContainer />} />
            <Route path="/error" element={<ErrorContainer />} />
            <Route path="/auth/callback" element={<CallbackContainer />} />
            <Route path="/*" element={<Navigate to="/" />} />
          </Routes>
        </Router>
        <ChatBot />
      </div>
    </div>
  )
}

export default App
