export const chatbotQuestions = [
  {
    id: 1,
    question: "מה זה My IDF?",
    answer: `My IDF הוא מנגנון ההזדהות הצה"לי לכל האתרים והאפליקציות של צה"ל באינטרנט!<br>

שם משתמש אחד וסיסמה אחת לכל משרת עבור כל האפליקציות.`,
  },
  {
    id: 2,
    question: `הזנתי מספר ת"ז ולא נשלח

אלי קוד לטלפון הנייד`,
    answer: `מספר הטלפון שברשותך אינו מעודכן ברישומת הצה"לית ויש לעדכנו. <br><br>
            <u>לחיילי חובה וקבע</u> - יש לעדכן את פרטי ההתקשרות מול קצין/ת המשא"ן ביחידה.<br><br>
            <u>גמלאים</u> - יש לעדכן את פרטי ההתקשרות מול מוקד מופת באמצעות חיוג למספר 1111.<br><br>
            <u>מילואים</u> - יש לעדכן את פרטי ההתקשרות מול קצין/ת הקישור.<br><br>
            <u>אזרחים עובדי צה"ל</u> - יש לעדכן את פרטי ההתקשרות מול קצין/ת האזרחים.`,
  },
  {
    id: 3,
    question: `קוד האימות הדו-שלבי לא מגיע
        לאחר שהזנתי את הסיסמה הראשונית
        `,
    answer: `תמיד אפשר לבקש קוד נוסף!<br>
            יש לפעול עפ"י הוראות התמיכה המפורסמות באתר בעת תהליך הרישום.`,
  },
  {
    id: 4,
    question: `אני מקבל שגיאה עבור כל סיסמה שאני בוחר`,
    answer: `אנחנו מגנים עליכם ברשת ולכן יש לבחור סיסמה העומדת בנהלי אבטחת המידע של צה"ל.<br>
            על הסיסמה להיות באורך של 8 תווים ולהכיל לפחות אות אחת גדולה באנגלית, אות אחת קטנה באנגלית
            וכן, צריכה לכלול בנוסף לאותיות גם מספרים ו/או סימנים.`,
  },
  {
    id: 5,
    question: `איך אפשר לקבל תמיכה נוספת?`,
    answer: `<p>אני כאן כדי לסייע לכם!</p>
        <p>לקבלת עזרה, ניתן לפנות בדרכים הבאות :</p>
        <p>טופס מקוון : &nbsp;</p>
        <p><a data-fr-linked="true" href="https://go.idf.il/Support">https://go.idf.il/Support</a></p>
        <p>מוקד תמיכה טלפוני :</p>
        <p>1800-071-212</p>
        <p>WhatsApp:</p>
        <p>052-4120234</p>`,
  },
];
