import { Alert, Button, CircularProgress, Container, Grid, Hidden, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import Carousel from 'react-elastic-carousel'
import arrow from '../../../assets/images/arrow.png'
import info1 from '../../../assets/images/info1.png'
import info3 from '../../../assets/images/info3.png'
import MsService from '../../../services/microsoft/MsService'
import MeApiService from '../../../services/rest/MeApiService'
import { ERRORS } from '../../Common/Errors/ErrorMessages'
import { MessageAlert } from '../../Common/MessageAlert'
import IFormProps from '../IForm'
import RegisteredUserSection from '../ValidateOtp/RegisteredUserSection'
import '../ValidateOtp/ValidateOtp.css'
import './AfterCodeReceivedForm.css'

function AfterCodeReceivedForm(props: IFormProps) {
  // State & props
  const [error, setError] = useState<any>({ msg: '' })
  const [isCreatingUser, setIsCreatingUser] = useState(true)
  const [isLoadingResetPassword, setIsLoadingResetPassword] = useState(false)
  const [isLoadingUnlockUser, setIsLoadingUnlockUser] = useState(false)
  const [isUserLocked, setIsUserLocked] = useState(props.payload.isUserLocked)
  const [showUserUnlockedMessage, setShowUserUnlockedMessage] = useState(false)
  const [showResetPasswordSuccessfulMessage, setShowResetPasswordSuccessfulMessage] = useState(false)
  const [currentUserUpn, setCurrentUserUpn] = useState('')
  const [isResetedSuccessfully, setIsResetedSuccessfully] = useState(false)

  const { userPrincipalName = '', isUserInitiallyLocked = '', isRegistered = false } = props.payload

  const handleUserResetPassword = async () => {
    try {
      setError({ msg: '', severity: 'error' })
      setIsLoadingResetPassword(true)
      setShowResetPasswordSuccessfulMessage(false)

      await MeApiService.resetUserPassword()
      setShowResetPasswordSuccessfulMessage(true)
      setIsResetedSuccessfully(true)
    } catch (err) {
      setError({
        msg: ERRORS.general,
        severity: 'error',
      })
    } finally {
      setIsLoadingResetPassword(false)
    }
  }

  const handleUserUnlock = async () => {
    try {
      setError({ msg: '', severity: 'error' })
      setIsLoadingUnlockUser(true)
      setShowUserUnlockedMessage(false)

      await MeApiService.unlockUser()
      setShowUserUnlockedMessage(true)
      setIsUserLocked(false)
    } catch (err) {
      setError({
        msg: ERRORS.general,
        severity: 'error',
      })
    } finally {
      setIsLoadingUnlockUser(false)
    }
  }

  useEffect(() => {
    onInit()
  }, [])

  const onInit = async () => {
    if (!isRegistered) {
      await createUser()
      setIsCreatingUser(false)
    }
  }
  // Handlers
  const onContinueClick = useCallback(async () => {
    if (currentUserUpn) {
      try {
        await MsService.login(`${currentUserUpn}`)
      } catch (err) {
        console.log(err)
        setError({ msg: ERRORS.general })
      }
    } else {
      setError({ msg: ERRORS.general })
    }
  }, [currentUserUpn])

  const createUser = async () => {
    setError({ msg: '' })

    try {
      const { upn } = await MeApiService.registerUser()
      setCurrentUserUpn(upn)
    } catch (err) {
      console.log(err)
      setError({ msg: ERRORS.general })
    }
  }

  const instructions = [
    { image: info1, text: 'במסך הבא, יש להקיש בשנית את הקוד שהתקבל ב-SMS' },
    { image: info3, text: 'לאחר מכן, יש לבחור סיסמה למשתמש' },
  ]

  return (
    <>
      {isRegistered ? (
        <MessageAlert
          className="MessageAlert"
          alert={{
            msg: (
              <RegisteredUserSection
                userPrincipalName={userPrincipalName}
                isUserInitallyLocked={isUserInitiallyLocked}
                isUserLocked={isUserLocked}
                isLoadingUnlock={isLoadingUnlockUser}
                isLoadingResetPassword={isLoadingResetPassword}
                showUserUnlockedMessage={showUserUnlockedMessage}
                showResetPasswordSuccessfulMessage={showResetPasswordSuccessfulMessage}
                onResetPassword={handleUserResetPassword}
                onUnlockUser={handleUserUnlock}
                isResetedPassword={isResetedSuccessfully}
              />
            ),
            severity: 'info',
          }}
        />
      ) : (
        <React.Fragment>
          {isCreatingUser ? (
            <Grid container item xs={12} justifyContent="center" alignItems="center" spacing={2}>
              <Grid item xs={12}>
                <CircularProgress size={48} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">מסיימים להגדיר עבורכם מספר דברים ומיד נמשיך...</Typography>
              </Grid>
            </Grid>
          ) : (
            <React.Fragment>
              <Container maxWidth="xs">
                <Typography className="bold">שירותי הדיגיטל של צה"ל שומרים על רמת אבטחה גבוהה.</Typography>
                <Typography style={{ marginBottom: '10px' }}>
                  לכן, לסיום תהליך הרישום, נצטרך לאמת את זהותך עוד פעם אחת באמצעות סיסמה זמנית שקיבלת זה עתה ב-SMS.
                </Typography>
              </Container>
              <Grid container justifyContent="center" alignItems="flex-start" style={{ marginTop: 20 }}>
                <Hidden lgDown>
                  {instructions.map((instruction, index) => (
                    <React.Fragment key={index}>
                      <Grid
                        item
                        md={3}
                        xs={12}
                        direction="column"
                        container
                        justifyContent="center"
                        alignItems="center"
                      >
                        <div className={'info-image-circle'}>
                          <img src={instruction.image} alt="instruction" className={'info-image'} />
                        </div>
                        <Typography align="center" className="info-text">
                          {instruction.text}
                        </Typography>
                      </Grid>
                      {index < instructions.length - 1 && (
                        <Grid item md={1} xs={12} style={{ alignSelf: 'center' }}>
                          <img src={arrow} alt="info" className={'info-arrow'} />
                        </Grid>
                      )}
                    </React.Fragment>
                  ))}
                </Hidden>
                <Hidden mdUp>
                  <Carousel isRTL={true}>
                    {instructions.map((instruction, index) => (
                      <Container key={index}>
                        <Grid direction="column" container justifyContent="center" alignItems="center">
                          <div className={'info-image-circle'}>
                            <img src={instruction.image} alt="instruction" className={'info-image'} />
                          </div>
                          <Typography align="center" className="info-text">
                            {instruction.text}
                          </Typography>
                        </Grid>
                      </Container>
                    ))}
                  </Carousel>
                </Hidden>

                <Grid container item xs={12} justifyContent="center" alignItems="center">
                  <Button
                    variant="contained"
                    onClick={onContinueClick}
                    disableElevation={true}
                    color="primary"
                    className="idf-button"
                  >
                    יאללה, אפשר להמשיך
                  </Button>
                </Grid>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  style={{ margin: '10px 0px' }}
                >
                  <Grid item xs={12}>
                    {error && error.msg !== '' ? <Alert severity="error">{error.msg}</Alert> : <React.Fragment />}
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </>
  )
}

export default AfterCodeReceivedForm
