import { Typography } from '@mui/material'
import LoadingButton from '../../Buttons/LoadingButton'

interface RegisteredUserSectionProps {
  userPrincipalName: string
  isUserLocked?: boolean
  isUserInitallyLocked?: boolean
  showUserUnlockedMessage?: boolean
  showResetPasswordSuccessfulMessage?: boolean
  isLoadingUnlock?: boolean
  isLoadingResetPassword?: boolean
  onResetPassword: () => void
  onUnlockUser?: () => void
  isResetedPassword: boolean
}

function RegisteredUserSection(props: RegisteredUserSectionProps) {
  const BlockedUserSection = () => (
    <>
      {props.isUserLocked ? (
        <>
          <Typography className="bold" style={{ marginTop: '15px' }}>
            לצערנו, <span style={{ color: 'red' }}>נראה שהמשתמש שלך חסום.</span>
          </Typography>
          {/* <Typography className="bold">לשחרור החסימה לחץ <a onClick={props.onUnlockUser}>כאן</a></Typography> */}
          <LoadingButton
            variant="contained"
            size="small"
            color="primary"
            onClick={props.onUnlockUser}
            isLoading={!!props.isLoadingUnlock}
            className="idf-button"
          >
            לחץ\י כאן לשחרור החסימה
          </LoadingButton>
        </>
      ) : props.showUserUnlockedMessage ? (
        <>
          <Typography className="bold" style={{ marginTop: '15px' }} variant="h6">
            חדשות טובות :)
          </Typography>
          <Typography>שחרור חסימה עבור המשתמש שלך בוצע בהצלחה!</Typography>
        </>
      ) : null}
    </>
  )

  const ResetPasswordSection = () => (
    <>
      {!props.isResetedPassword && (
        <>
          <Typography style={{ marginTop: '15px' }} className="bold">
            שכחת את הסיסמה?
          </Typography>

          <LoadingButton
            variant="contained"
            size="small"
            color="primary"
            onClick={props.onResetPassword}
            isLoading={!!props.isLoadingResetPassword}
            className="idf-button"
          >
            לחץ\י כאן לאיפוס הסיסמה
          </LoadingButton>
        </>
      )}

      {props.showResetPasswordSuccessfulMessage && (
        <Typography variant="body2" style={{ color: 'green' }}>
          קישור לאיפוס הסיסמה נשלח למכשירך בהצלחה!
        </Typography>
      )}
    </>
  )

  return (
    <div>
      <Typography className="bold">נראה שכבר נרשמת לשירות :)</Typography>
      <Typography className="bold" style={{ marginTop: '10px' }}>
        שם המשתמש האישי שלך הינו:
      </Typography>
      <Typography variant="h5" style={{ color: '#008000' }} className="english-font, bold" dir="ltr">
        {props.userPrincipalName}
      </Typography>

      {props.isUserInitallyLocked && <BlockedUserSection />}

      {!props.isUserLocked && <ResetPasswordSection />}
    </div>
  )
}

export default RegisteredUserSection
