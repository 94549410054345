import { Alert, Grid, GridProps } from '@mui/material'

interface IMessageAlert {
  msg: any
  severity: any
}

interface MessageAlertProps extends GridProps {
  alert?: IMessageAlert
}
export function MessageAlert(props: MessageAlertProps) {
  const { alert } = props

  return (
    <Grid item xs={12} {...props}>
      {alert && alert.msg !== '' ? (
        <Alert severity={alert.severity} className="info-container">
          {alert.msg}
        </Alert>
      ) : (
        <></>
      )}
    </Grid>
  )
}
