import { Button, Grid, Typography } from '@mui/material'
import IFormProps from '../IForm'
import './SecurityAgreement.css'
import myTextFile from './SecurityAgreement.json'

export const SecurityAgreement = (props: IFormProps) => {
  const agreementText = myTextFile.value

  return (
    <section>
      <Typography className="font-header">הצהרת המשתמש בנושא:</Typography>
      <Typography className="font-header bold">שמירה על נהלי בטחון מידע</Typography>
      <br />
      <div className="container">
        <pre className="content">{agreementText}</pre>
      </div>
      <br />
      <Grid container className="button">
        <Grid item>
          <Button variant="contained" style={{ background: '#FEE75D', width: '200px' }} onClick={props.onResolve}>
            קראתי ואני מאשר
          </Button>
        </Grid>
      </Grid>
    </section>
  )
}
